import gql from 'graphql-tag';
import { FetchResult } from '@apollo/client';
import { client as apolloClient } from './client';
import { MSetupSubscription, MSetupSubscriptionVariables } from './gen/MSetupSubscription';
import { onMutationError } from './lib/errorReporter';
import { ApolloError } from '@apollo/client';
import { MSendUserNotification, MSendUserNotificationVariables } from './gen/MSendUserNotification';
import { MSetupPayment, MSetupPaymentVariables } from './gen/MSetupPayment';
import { QCompanyValidateCoupon, QCompanyValidateCouponVariables } from './gen/QCompanyValidateCoupon';
import { MConfigInvoiceItemVariables, MConfigInvoiceItem } from './gen/MConfigInvoiceItem';
import { Stripe } from 'stripe';
import { MInvoiceCustomer, MInvoiceCustomerVariables } from './gen/MInvoiceCustomer';
import { MFinalizeInvoice, MFinalizeInvoiceVariables } from './gen/MFinalizeInvoice';
import { MVoidInvoice, MVoidInvoiceVariables } from './gen/MVoidInvoice';
import { MCancelSubscription, MCancelSubscriptionVariables } from './gen/MCancelSubscription';
import { MUpdateCompany, MUpdateCompanyVariables } from './gen/MUpdateCompany';
import { MPresentarModelo, MPresentarModeloVariables } from './gen/MPresentarModelo';
import { MSetupPaymentCustomer, MSetupPaymentCustomerVariables } from './gen/MSetupPaymentCustomer';
import { MDeleteDoc, MDeleteDocVariables, MDeleteDoc_result } from './gen/MDeleteDoc';
import { MNoNotify, MNoNotifyVariables } from './gen/MNoNotify';
import { MfinishTaskGroup, MfinishTaskGroupVariables } from './gen/MfinishTaskGroup';
import { MBuyStoreItem, MBuyStoreItemVariables } from './gen/MBuyStoreItem';
import { MValidarModelo, MValidarModeloVariables } from './gen/MValidarModelo';
import dayjs from 'dayjs';
import { MSleepTask, MSleepTaskVariables } from './gen/MSleepTask';
import { MReviewCompany, MReviewCompanyVariables } from './gen/MReviewCompany';
import { MReprocessDoc, MReprocessDocVariables } from './gen/MReprocessDoc';
import { MUpdateAccounting, MUpdateAccountingVariables } from './gen/MUpdateAccounting';
import { QGetDocAccounting, QGetDocAccountingVariables } from './gen/QGetDocAccounting';
import { Extraction, ExtractionMode } from './gen/globalTypes';
import { MResolveExtraction, MResolveExtractionVariables, MResolveExtraction_resolveExtraction_tasks } from './gen/MResolveExtraction';
import { Qprql, QprqlVariables } from './gen/Qprql';
import { QGetDocExtraction } from './gen/QGetDocExtraction';
import { MModifyExtraction, MModifyExtractionVariables } from './gen/MModifyExtraction';
import { MSetExtractionTrainingExtraction, MSetExtractionTrainingExtractionVariables } from './gen/MSetExtractionTrainingExtraction';

// ----------------------------------------
//     QUERIES
// ----------------------------------------

export const COMPANY_PAYMENT_INFO_QUERY = gql`
query QCompanyPaymentInfo($company:ID!) {
  company(id: $company) {
    id
    name
    taxId
    accountingLockDate
    banReason
    banned
  }
}
`;


export const COMPANY_VALIDATE_COUPON_QUERY = gql`
query QCompanyValidateCoupon(
  $company: ID!
  $coupon: String!
  $planId: String!
) {
  company(id: $company) {
    id
    paymentCustomerId
    isValidCoupon(coupon: $coupon, planId: $planId) {
      result
      message
      coupon
    }
  }
}
`;

// ----------------------------------------
//     MUTATIONS
// ----------------------------------------

export const SET_SIGNUP_COUPON = gql`
mutation MSetSignupCoupon(
  $company: ID!,
  $coupon: String!
) {
  setSignupCoupon(
    company: $company,
    coupon: $coupon
  )
}
`;

export const SETUP_SUBSCRIPTION_MUTATION = gql`
mutation MSetupSubscription(
  $company: ID!, 
  $planId: String,
  $startDate: Date, 
  $coupon: String,
  $removePendingInvoiceItems: Boolean
) {
  setupSubscription(
    company: $company
    planId: $planId
    startDate: $startDate
    coupon: $coupon
    removePendingInvoiceItems: $removePendingInvoiceItems
  ) {
    id
    rawSubscription
  }
}
`;

export const CANCEL_SUBSCRIPTION_MUTATION = gql`
mutation MCancelSubscription(
  $company: ID!
) {
  cancelSubscription(company: $company) {
    id
    rawSubscription
  }
}
`;

const CONFIG_INVOICE_ITEM_MUTATION = gql`
mutation MConfigInvoiceItem(
  $customer: ID!,
  $id: ID, 
  $amount: Int,
  $description: String, 
  $discountable: Boolean,
  $delete: Boolean,
) {
  configInvoiceItem(
    customer: $customer
    id: $id
    amount: $amount
    description: $description
    discountable: $discountable
    delete: $delete
  )
}
`;

const INVOICE_CUSTOMER_MUTATION = gql`
mutation MInvoiceCustomer(
  $customer: ID!
  $collectionMethod: String
) {
  invoiceCustomer(
    customer: $customer
    collectionMethod: $collectionMethod
  )
}
`;

const FINALIZE_INVOICE_MUTATION = gql`
mutation MFinalizeInvoice(
  $invoice: ID!
  $pay: Boolean!
) {
  finalizeInvoice(
    invoice: $invoice
    pay: $pay
  )
}
`;

const VOID_INVOICE_MUTATION = gql`
mutation MVoidInvoice(
  $invoice: ID!
) {
  voidInvoice(
    invoice: $invoice
  )
}
`;

export const UPDATE_COMPANY_MUTATION = gql`
mutation MUpdateCompany(
  $company: ID!
  $name: String
  $taxId: String
  $accountingLockDate: Date
) {
  updateCompany(
    company: $company
    name: $name
    taxId: $taxId
    accountingLockDate: $accountingLockDate
  ) {
    id
  }
}
`;

export const SEND_USER_NOTIFICATION = gql`
  mutation MSendUserNotification(
    $user: ID, 
    $message: UserNotificationMessage!, 
    $channels: [UserNotificationChannel!]!,
    $from: String!,
    $company: ID!,
  ) {
    sendUserNotification(
        user: $user,
        company: $company,
        message: $message,
        from: $from,
        channels: $channels,
    )
  }
  `;

const SETUP_PAYMENT_MUTATION = gql`
mutation MSetupPayment(
  $company: ID!, 
  $source: ID!
) {
  setupPayment(
    company: $company
    source: $source
  ) {
    id
    paymentCustomerId
  }
}
`;

export const SETUP_PAYMENT_CUSTOMER_MUTATION = gql`
mutation MSetupPaymentCustomer(
  $company: ID!, 
  $name: String!, 
  $surname: String, 
  $taxId: String!, 
  $address: String!, 
  $city: String!, 
  $postalCode: String!
) {
  setupPaymentCustomer(
    company: $company,
    name: $name,
    surname: $surname,
    taxId: $taxId,
    address: $address,
    city: $city,
    postalCode: $postalCode
  )
}
`;


export async function setupSubscription(params: {
  company: string,
  planId?: string,
  startDate?: string,
  coupon?: string,
  removePendingInvoiceItems?: boolean
}): Promise<boolean> {
  return await apolloClient.mutate<MSetupSubscription, MSetupSubscriptionVariables>({
    mutation: SETUP_SUBSCRIPTION_MUTATION,
    variables: {
      company: params.company,
      planId: params.planId,
      startDate: params.startDate,
      coupon: params.coupon,
      removePendingInvoiceItems: params.removePendingInvoiceItems,
    }
  }).then((result: FetchResult<MSetupSubscription>) => {
    if (result.data) {
      return true;
    }
    return false;
  }).catch((error: ApolloError) => {
    onMutationError(error);
    return false;
  });
}

export async function cancelSubscription(params: {
  company: string
}): Promise<boolean> {

  try {
    const result = await apolloClient.mutate<MCancelSubscription, MCancelSubscriptionVariables>({
      mutation: CANCEL_SUBSCRIPTION_MUTATION,
      variables: {
        company: params.company,
      }
    });
    return !!result.data;
  } catch (error) {
    onMutationError(error);
    return false;
  }
}

export async function setupPayment(params: {
  company: string,
  source: string,
}): Promise<MSetupPayment | undefined> {
  return await apolloClient.mutate<MSetupPayment, MSetupPaymentVariables>({
    mutation: SETUP_PAYMENT_MUTATION,
    variables: {
      company: params.company,
      source: params.source,
    }
  }).then((result: FetchResult<MSetupPayment>) => {
    return result.data!;
  }).catch((error: ApolloError) => {
    onMutationError(error);
    return undefined;
  });
}

export async function configStripeInvoiceItem(
  variables: MConfigInvoiceItemVariables
): Promise<Stripe.InvoiceItem | undefined> {
  return await apolloClient.mutate<MConfigInvoiceItem, MConfigInvoiceItemVariables>({
    mutation: CONFIG_INVOICE_ITEM_MUTATION,
    variables
  }).then((result: FetchResult<MConfigInvoiceItem>) => {
    return result.data as (Stripe.InvoiceItem | undefined);
  }).catch((error: ApolloError) => {
    onMutationError(error);
    return undefined;
  });
}

export async function invoiceCustomer(customer: string, collectionMethod: string): Promise<Stripe.Invoice | undefined> {
  return await apolloClient.mutate<MInvoiceCustomer, MInvoiceCustomerVariables>({
    mutation: INVOICE_CUSTOMER_MUTATION,
    variables: { customer, collectionMethod }
  }).then((result: FetchResult<MInvoiceCustomer>) => {
    return result.data?.invoiceCustomer as unknown as Stripe.Invoice;
  }).catch((error: ApolloError) => {
    onMutationError(error);
    return undefined;
  });
}

export async function finalizeInvoice(invoice: string, pay: boolean): Promise<Stripe.Invoice | undefined> {
  return await apolloClient.mutate<MFinalizeInvoice, MFinalizeInvoiceVariables>({
    mutation: FINALIZE_INVOICE_MUTATION,
    variables: { invoice, pay }
  }).then((result: FetchResult<MFinalizeInvoice>) => {
    return result.data?.finalizeInvoice as unknown as Stripe.Invoice;
  }).catch((error: ApolloError) => {
    onMutationError(error);
    return undefined;
  });
}

export async function voidInvoice(invoice: string): Promise<Stripe.Invoice | undefined> {
  return await apolloClient.mutate<MVoidInvoice, MVoidInvoiceVariables>({
    mutation: VOID_INVOICE_MUTATION,
    variables: { invoice }
  }).then((result: FetchResult<MVoidInvoice>) => {
    return result.data as unknown as Stripe.Invoice;
  }).catch((error: ApolloError) => {
    onMutationError(error);
    return undefined;
  });
}


export async function setupPaymentCustomer(
  company: string,
  name: string,
  surname: string,
  taxId: string,
  address: string,
  postalCode: string,
  city: string
): Promise<boolean> {
  return await apolloClient.mutate<MSetupPaymentCustomer, MSetupPaymentCustomerVariables>({
    mutation: SETUP_PAYMENT_CUSTOMER_MUTATION,
    variables: { company, name, surname, taxId, address, postalCode, city },
    fetchPolicy: 'no-cache'
  }).then((result: FetchResult<MSetupPaymentCustomer>) => {
    return result.data?.setupPaymentCustomer ?? false;
  }).catch((error: ApolloError) => {
    onMutationError(error);
    return false;
  });
}

export async function updateCompany(
  params: MUpdateCompanyVariables
): Promise<boolean> {
  return await apolloClient.mutate<MUpdateCompany, MUpdateCompanyVariables>({
    mutation: UPDATE_COMPANY_MUTATION,
    variables: params,
    fetchPolicy: 'no-cache'
  }).then((result: FetchResult<MUpdateCompany>) => {
    if (result.data) {
      return true;
    }
    return false;
  }).catch((error: ApolloError) => {
    onMutationError(error);
    return false;
  });
}

export async function sendUserNotification(
  variables: MSendUserNotificationVariables
): Promise<boolean> {
  return await apolloClient.mutate<MSendUserNotification, MSendUserNotificationVariables>({
    mutation: SEND_USER_NOTIFICATION,
    variables,
    fetchPolicy: 'no-cache'
  }).then((result: FetchResult<MSendUserNotification>) => {
    if (result.data) {
      return true;
    }
    return false;
  }).catch((error: ApolloError) => {
    onMutationError(error);
    return false;
  });
}

export async function validateCoupon(variables: QCompanyValidateCouponVariables): Promise<QCompanyValidateCoupon> {
  return (await apolloClient.query<QCompanyValidateCoupon, QCompanyValidateCouponVariables>({
    query: COMPANY_VALIDATE_COUPON_QUERY,
    variables
  })).data;
}

export const PRESENTAR_MODELO_MUTATION = gql`
mutation MPresentarModelo(
  $company: ID!
  $id: String!
  $force: Boolean!
) {
  presentarModelo(
    company: $company,
    id: $id,
    force: $force
  ) {
    csv
  }
}
`;
export async function presentarModelo(variables: MPresentarModeloVariables): Promise<MPresentarModelo | null | undefined> {
  return (await apolloClient.mutate<MPresentarModelo, MPresentarModeloVariables>({
    mutation: PRESENTAR_MODELO_MUTATION,
    variables,

  })).data;
}

export const DELETE_DOC_MUTATION = gql`
  mutation MDeleteDoc($company:ID!, $id:ID!, $deleted:Boolean!, $check:Boolean, $destroy: Boolean){
    result: deleteDoc(company: $company, id: $id, deleted:$deleted, check:$check, destroy: $destroy) {
      deleted,
      errors {
        title {es}
      }
    }
  }
`;

export async function deleteDoc(variables: MDeleteDocVariables): Promise<MDeleteDoc_result | undefined> {
  return (await apolloClient.mutate<MDeleteDoc, MDeleteDocVariables>({
    mutation: DELETE_DOC_MUTATION,
    variables
  })).data?.result;
}

const NO_NOTIFY_MUTATION = gql`
mutation MNoNotify(
  $company: ID!, 
  $tasks: [ID!]!,
) {
  markNotified(
    company: $company
    tasks: $tasks
  )
}
`;

export async function markNotified(variables: MNoNotifyVariables): Promise<boolean | undefined> {
  return (await apolloClient.mutate<MNoNotify, MNoNotifyVariables>({
    mutation: NO_NOTIFY_MUTATION,
    variables
  })).data?.markNotified;
}

const DELETE_GROUP_TASK_MUTATION = gql`
mutation MfinishTaskGroup(
  $company: ID!, 
  $id: ID!,
  $body: String!,
) {
  finishTaskGroup(
    company: $company,
    id: $id,
    body: $body
  )
}`;

export async function deleteGroupTask(company: string, id: string, body: string) {
  await apolloClient.mutate<MfinishTaskGroup, MfinishTaskGroupVariables>(
    {
      mutation: DELETE_GROUP_TASK_MUTATION,
      variables: { company, id, body }
    }
  )
}

export const BUY_STORE_ITEM_MUTATION = gql`
  mutation MBuyStoreItem(
    $company: ID!, 
    $id: ID!,
  ) {
    buyStoreItem(
      company: $company,
      id: $id
    ) {
      taskGroupId
    }
  }`;

export async function buyStoreItem(company: string, id: string) {
  await apolloClient.mutate<MBuyStoreItem, MBuyStoreItemVariables>(
    {
      mutation: BUY_STORE_ITEM_MUTATION,
      variables: { company, id }
    }
  )
}

const VALIDAR_MODELO_MUTATION = gql`
mutation MValidarModelo(
  $company: ID!
  $id: String!
) {
  validarModelo(
    company: $company,
    id: $id
  ) {
    url
  }
}
`;

export async function validarModelo(args: { reference: string, companyId: string }): Promise<string | undefined> {
  const { data } = await apolloClient.mutate<MValidarModelo, MValidarModeloVariables>({
    mutation: VALIDAR_MODELO_MUTATION,
    variables: { id: args.reference, company: args.companyId },
    fetchPolicy: "no-cache"
  });
  return data?.validarModelo.url
}

const SLEEP_TASK_MUTATION = gql`
  mutation MSleepTask(
  $company: ID!
  $id: ID!
  $sleepDate: Date
  ) {
    sleepTask(
      company: $company,
  id: $id,
  sleepDate: $sleepDate,
  )
}
`;

export async function sleepTask(id: string, company: string, days: number) {
  try {
    console.log(arguments);
    const sleepDate = days === 0 ? null : dayjs().add(days, 'day').format('YYYY-MM-DD');
    await apolloClient.mutate<MSleepTask, MSleepTaskVariables>({
      mutation: SLEEP_TASK_MUTATION,
      variables: { id, company, sleepDate },
      fetchPolicy: 'no-cache'
    });
  } catch (err) {
    onMutationError(err);
  }
}

export const REVIEW_COMPANY_MUTATION = gql`
mutation MReviewCompany(
  $company: ID!
) {
  reviewCompany(
    company: $company,
  )
}
`;

export async function reviewCompany(company: string) {
  await apolloClient.mutate<MReviewCompany, MReviewCompanyVariables>({
    mutation: REVIEW_COMPANY_MUTATION,
    variables: { company }
  });
}

export const REPROCESS_DOC_MUTATION = gql`
mutation MReprocessDoc(
  $docId: ID!
) {
  reprocessDoc(
    docId: $docId,
  ) {
    id
  }
}
`;

export async function reprocessDoc(docId: string) {
  await apolloClient.mutate<MReprocessDoc, MReprocessDocVariables>({
    mutation: REPROCESS_DOC_MUTATION,
    variables: { docId }
  });
}

export const UPDATE_ACCOUNTING_MUTATION = gql`
mutation MUpdateAccounting(
  $companyId: ID!,
  $docId: ID!,
  $accounting: String!,
  $oldAccounting: String,
  $check: Boolean!
) {
  updateAccounting(company: $companyId, id: $docId, accounting: $accounting, oldAccounting: $oldAccounting, check: $check) {
    id
    title {
      es
    }
  }
}
`;

export async function editDoc(args: { companyId: string, docId: string, accounting: string, oldAccounting?: string, check: boolean }): Promise<Array<{ id: string, title: { es: string } }>> {
  const res = await apolloClient.mutate<MUpdateAccounting, MUpdateAccountingVariables>({
    mutation: UPDATE_ACCOUNTING_MUTATION,
    variables: args
  });
  return res.data!.updateAccounting;
}

export const GET_DOC_ACCOUNTING = gql`
query QGetDocAccounting(
  $companyId: ID!,
  $docId: ID!,
) {
  company(id: $companyId) {
    id,
    doc(id:$docId) {
      id,
      accounting,
      deleted
    }
  }
}
`;

export const GET_DOC_EXTRACTION = gql`
query QGetDocExtraction(
  $companyId: ID!,
  $docId: ID!,
) {
  company(id: $companyId) {
    id,
    doc(id:$docId) {
      id,
      extraction,
      deleted
    }
  }
}
`;

export async function getDocAccounting(args: { companyId: string, docId: string }): Promise<{ id: string, accounting: string, deleted: boolean } | null> {
  const res = await apolloClient.query<QGetDocAccounting, QGetDocAccountingVariables>({
    query: GET_DOC_ACCOUNTING,
    variables: args
  });
  return res.data.company?.doc ?? null;
}

export async function getDocExtraction(args: { companyId: string, docId: string }): Promise<{ id: string, extraction: Extraction, deleted: boolean } | null> {
  const res = await apolloClient.query<QGetDocExtraction, QGetDocAccountingVariables>({
    query: GET_DOC_EXTRACTION,
    variables: args
  });
  return res.data.company?.doc ?? null;
}


const RESOLVE_EXTRACTION_MUTATION = gql`
  mutation MResolveExtraction($id: ID!, $extraction: Extraction!, $check:Boolean!, $mode:ExtractionMode!) {
    resolveExtraction(id: $id, extraction: $extraction, check: $check, mode: $mode) {
      resolved
      id
      tasks {
        title { es }
      }
    }
  }
`;

export async function resolveExtraction(id: string, extraction: Extraction, check: boolean, mode: ExtractionMode): Promise<MResolveExtraction_resolveExtraction_tasks[]> {
  try {
    let res = await apolloClient.mutate<MResolveExtraction, MResolveExtractionVariables>({
      mutation: RESOLVE_EXTRACTION_MUTATION,
      variables: {
        id,
        check,
        extraction,
        mode
      }
    });
    return res.data!.resolveExtraction.tasks;
  } catch (err) {
    onMutationError(err)
    throw err;
  }
}

const MODIFY_EXTRACTION_MUTATION = gql`
  mutation MModifyExtraction($id: ID!, $extraction: Extraction!) {
    modifyExtraction(id: $id, extraction: $extraction)
  }
`;

export async function modifyExtraction(id: string, extraction: Extraction): Promise<boolean> {
  try {
    let res = await apolloClient.mutate<MModifyExtraction, MModifyExtractionVariables>({
      mutation: MODIFY_EXTRACTION_MUTATION,
      variables: {
        id,
        extraction,
      }
    });
    return res.data!.modifyExtraction;
  } catch (err) {
    onMutationError(err)
    throw err;
  }
}

const SET_EXTRACTION_TRAINING_MUTATION = gql`
  mutation MSetExtractionTrainingExtraction($id: ID!, $training: Boolean!, $discard: Boolean!) {
    setExtractionTraining(id: $id, training: $training, discard: $discard)
  }
`;

export async function setExtractionTraining(id: string, training: boolean, discard: boolean): Promise<boolean> {
  try {
    let res = await apolloClient.mutate<MSetExtractionTrainingExtraction, MSetExtractionTrainingExtractionVariables>({
      mutation: SET_EXTRACTION_TRAINING_MUTATION,
      variables: {
        id,
        training,
        discard
      }
    });
    return res.data!.setExtractionTraining;
  } catch (err) {
    onMutationError(err)
    throw err;
  }
}

export const PRQL_QUERY_WITH_COUNT = gql`
query QprqlCount($prql: String!,$prqlcount: String!) {
    prql(
      prql: $prql
    )
    count:prql(
      prql: $prqlcount
    )
}
`;

export const PRQL_QUERY = gql`
query Qprql($prql: String!) {
    prql(
      prql: $prql
    )
}
`;

export interface PRQLResult {
  rows: any[];
  fields: Field[];
  duration: number;
}

export interface Field {
  name: string;
  format: string;
  dataTypeID: number;
}

export async function prqlQuery(prql: string): Promise<PRQLResult> {
  try {
    let res = await apolloClient.query<Qprql, QprqlVariables>({
      query: PRQL_QUERY,
      variables: {
        prql
      }
    });
    return res.data!.prql as PRQLResult;
  } catch (err) {
    onMutationError(err)
    throw err;
  }
}
